import {
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

export type ImagePayload = {
  altText?: string
  src: string
  driveId: string
  itemId: string
  key?: NodeKey
}

export type SerializedImageNode = Spread<
  {
    src: string
    altText?: string
    type: 'onedrive-image'
    driveId: string
    itemId: string
    version: 1
  },
  SerializedLexicalNode
>

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string
  __altText: string
  __driveId: string
  __itemId: string

  constructor(
    src: string,
    driveId: string,
    itemId: string,
    altText?: string,
    key?: NodeKey
  ) {
    super(key)
    this.__src = src
    this.__altText = altText ?? ''
    this.__driveId = driveId
    this.__itemId = itemId
  }

  static getType(): 'onedrive-image' {
    return 'onedrive-image'
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__driveId,
      node.__itemId,
      node.__altText
    )
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    return $createImageNode({
      src: serializedNode.src,
      altText: serializedNode.altText,
      driveId: serializedNode.driveId,
      itemId: serializedNode.itemId,
    })
  }

  createDOM(): HTMLImageElement {
    const el = document.createElement('img')
    el.src = this.__src
    el.alt = this.__altText
    el.dataset.driveId = this.__driveId
    el.dataset.itemId = this.__itemId

    return el
  }

  updateDOM(): boolean {
    return false
  }

  exportJSON(): SerializedImageNode {
    return {
      type: ImageNode.getType(),
      altText: this.__altText,
      src: this.__src,
      driveId: this.__driveId,
      itemId: this.__itemId,
      version: 1,
    }
  }

  decorate(): JSX.Element {
    return (
      <img
        src={this.__src}
        alt={this.__altText}
        data-drive-id={this.__driveId}
        data-item-id={this.__itemId}
      />
    )
  }

  setSrc(src: string) {
    this.__src = src
  }

  setItem(itemId: string, driveId: string) {
    this.__driveId = driveId
    this.__itemId = itemId
  }
}

export const $createImageNode = (payload: ImagePayload): ImageNode => {
  return new ImageNode(
    payload.src,
    payload.driveId,
    payload.itemId,
    payload.altText,
    payload.key
  )
}

export const $isImageNode = (
  node: LexicalNode | null | undefined
): node is ImageNode => {
  return node instanceof ImageNode
}
