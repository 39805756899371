import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import placeholder from '../../images/placeholder.png'
import { ChatImage } from './ChatImage'
import styles from './ChatInputPastedImage.module.css'
import { thumbnailsSelectors } from './thumbnailsSlice'

// このコンポネートはメッセージ入力欄に貼り付けた画像を入力欄に表示させるためのコンポネートです。
const ChatInputPastedImage: React.FC<{ id: number }> = (props): JSX.Element => {
  const pastedImagesTokensState = useSelector(
    (state: RootState) => state.imageTokens
  )
  const thumbnails = useSelector(thumbnailsSelectors.selectAll)

  const src = useMemo(() => {
    const item = pastedImagesTokensState.entities[props.id]
    if (item == null) {
      return
    }

    // 貼り付け画像をまたonedriveにアップロード完了していないです。
    const itemId = item.itemId
    if (itemId == null) {
      return
    }

    const token = item.token
    // 貼り付けた画像のtokenはまた取得できていないです。
    if (token == null) {
      return
    }

    const thumbnail = thumbnails.find((c) => c.itemId === itemId)
    // アップロードして、onedriveの情報をチェックします
    if (thumbnail == null) {
      return
    }

    // サムネールを取得し、入力欄に表示させます。
    const thumbnailUrl = [
      thumbnail.thumbnails?.large?.url,
      thumbnail.thumbnails?.medium?.url,
      thumbnail.thumbnails?.small?.url,
    ].find((i) => i)

    return thumbnailUrl
  }, [props.id, pastedImagesTokensState, thumbnails])

  const imgInfo = useMemo(() => {
    /*src(サムネール)また準備できていないなら、placeholder画像を入力欄に表示させます*/
    if (src == null) {
      return {
        src: placeholder,
        text: '画像を読み込んでいます...',
      }
    }

    /*入力欄に表示させます*/
    return {
      src: src,
    }
  }, [src])

  return (
    <div className={styles.pastedImageContainer}>
      <ChatImage
        id={`${props.id}`}
        src={imgInfo.src}
        className={styles.img}
        alt={''}
        text={imgInfo.text}
      />
    </div>
  )
}

export default ChatInputPastedImage
