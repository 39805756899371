import { Dispatch } from 'react'

import { AppThunk } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import {
  BotChannelStorageImageData,
  BotChannelStorageState,
  fetchBotChannelStorageImage,
} from './botChannelStorageImagesSlice'
import { prepareInquiry } from './thumbnailsQuery'
import { ThumbnailsState, ThumbnailsStatus } from './thumbnailsSlice'

export declare type ImageResourceResult = {
  src?: string
  type?: 'url' | 'base64'
  errorMessage?: 'NotReady' | 'NotFound'
}
export interface PastedImageResource<T> {
  query: (dispatch: Dispatch<AppThunk<void>>) => void
  find: (imageItems: T[]) => ImageResourceResult
}

const isOneDrivePattern = (src: string, host: string): boolean => {
  const url = new URL(src)
  if (url.host !== host) {
    return false
  }

  if (url.pathname !== '/api/v1/images') {
    return false
  }
  const itemId = url.searchParams.get('item_id')
  if (itemId == null) {
    return false
  }
  const driveId = url.searchParams.get('drive_id')
  const groupId = url.searchParams.get('group_id')
  return driveId != null || groupId != null
}

// BotChannelStorageリクエスター側からきた貼り付け画像のURLの汎用正規表現
const BotChannelStoragePattern = new RegExp(
  `https://.*?/objects/(.+?)/views/.*?`,
  'i'
)

export const createPastedImageResource = (
  src: string
):
  | PastedImageResource<ThumbnailsState>
  | PastedImageResource<BotChannelStorageImageData>
  | undefined => {
  if (BotChannelStoragePattern.test(src)) {
    return newBotChannelStorageImageResource(src)
  }

  const hostURL = new URL(process.env.REACT_APP_SERVER_URL)

  if (isOneDrivePattern(src, hostURL.host)) {
    return newOneDriveImageResource(src)
  }

  return
}

const newOneDriveImageResource = (
  src: string
): PastedImageResource<ThumbnailsState> | undefined => {
  const url = new URL(src)
  const itemId = url.searchParams.get('item_id')
  const driveId = url.searchParams.get('drive_id')
  const groupId = url.searchParams.get('group_id')

  if (itemId == null) {
    return
  }

  const props: { driveId?: string; groupId?: string } = {}

  if (driveId != null) {
    props.driveId = driveId
  }

  if (groupId != null) {
    props.groupId = groupId
  }
  return new OneDriveImageResource(src, itemId, props)
}

// リクエスターからきた貼り付け画像をOneDriveに保存した場合は、こちらのInquiryで対応します。
export class OneDriveImageResource
  implements PastedImageResource<ThumbnailsState>
{
  constructor(
    private src: string,
    private itemId: string,
    private props: { driveId?: string; groupId?: string }
  ) {}

  query(dispatch: Dispatch<AppThunk<void>>): void {
    const inquiry = prepareInquiry({
      groupId: this.props.groupId,
      itemId: this.itemId,
      driveId: this.props.driveId,
    })

    if (inquiry == null) {
      consoleErrorWithAirbrake(
        `get empty inquiry when tying to fetch thumbnail with driveId:${this.props.driveId} and itemId:${this.itemId}`
      )
      return
    }
    dispatch(inquiry.query())
  }

  find(items: ThumbnailsState[]): ImageResourceResult {
    const exchangeThumbnail = items.find(
      (thumbnail) => thumbnail.itemId === this.itemId
    )

    if (exchangeThumbnail?.status === ThumbnailsStatus.NotFound) {
      return {
        errorMessage: 'NotFound',
      }
    }

    // // サムネールまた準備できていないなら、placeholderを表示させます。
    if (exchangeThumbnail == null || exchangeThumbnail.thumbnails == null) {
      return {
        errorMessage: 'NotReady',
      }
    }

    const thumbnailObj = [
      exchangeThumbnail.thumbnails['large'],
      exchangeThumbnail.thumbnails['medium'],
      exchangeThumbnail.thumbnails['small'],
    ].find((i) => i)

    // // サムネールまた準備できていないなら、placeholderを表示させます。
    if (thumbnailObj == null) {
      return { errorMessage: 'NotReady' }
    }

    return { src: thumbnailObj.url, type: 'url' }
  }
}

// リクエスター側からの貼り付け画像を抽出します。
// extract attachmentID from richText
// 手入力したimgタグは&lt;imgに変換されましたので、下記の正規表現に該当しないです
export const extractAttachmentIdFromSrc = (src: string): string | undefined => {
  const result = src.match(BotChannelStoragePattern)
  if (result == null) {
    return
  }
  if (result.length < 2) {
    return
  }
  return result[1]
}

const newBotChannelStorageImageResource = (
  src: string
): PastedImageResource<BotChannelStorageImageData> | undefined => {
  const attachmentId = extractAttachmentIdFromSrc(src)
  if (attachmentId == null) {
    return
  }
  return new BotChannelStorageImageResource(src, attachmentId, 'original')
}

// リクエスターからの貼り付け画像をonedriveに保存せなかった場合は、こちらのInquiryで画像を対応します。
export class BotChannelStorageImageResource
  implements PastedImageResource<BotChannelStorageImageData>
{
  constructor(
    private src: string,
    private attachmentId: string,
    private viewId: string
  ) {}

  query(dispatch: Dispatch<AppThunk<void>>): void {
    dispatch(fetchBotChannelStorageImage(this.attachmentId, this.viewId))
  }

  find(items: BotChannelStorageImageData[]): ImageResourceResult {
    // 該当する画像のblobデータを取り出します。
    const imageData = items
      .filter((item) => item.viewId === 'original')
      .find((item) => item.attachmentId === this.attachmentId)

    // リクエスタ差し替える材料がなければ、レンダリングしません
    if (!imageData) {
      return { errorMessage: 'NotReady' }
    }

    // 画像が削除されることにより404に対して、画像が見つかりませんでしたを表示させます。
    if (imageData.dataStatus === BotChannelStorageState.NotFound) {
      return { errorMessage: 'NotFound' }
    }

    // リクエスタ差し替える材料がなければ、レンダリングしません
    if (!imageData.data) {
      return { errorMessage: 'NotReady' }
    }

    // 表示するために、base64形式に組み立てます。
    return { src: `data:image/png;base64,${imageData.data}`, type: 'base64' }
  }
}
