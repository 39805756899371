import { GraphError, GraphRequest } from '@microsoft/microsoft-graph-client'
import { EntityId } from '@reduxjs/toolkit'
import { Dispatch } from 'react'

import { AppThunk, GetState } from '../../app/store'
import { consoleErrorWithAirbrake } from '../../utils'
import { Alert, addAlert } from '../alert/alertsSlice'
import { resetTokens } from '../auth/authSlice'
import { isImage } from './common'
import { fetchImageToken } from './imageTokensSlice'
import {
  uploadAttachmentOnEnd,
  uploadAttachmentOnThumbnailSizedFetched,
  uploadAttachmentProcessOnEnd,
  uploadAttachmentProcessOnProgress,
  uploadAttachmentProcessOnRemove,
  uploadAttachmentProcessOnStart,
} from './messageAttachmentsSlice'
import { fetchThumbnailOnMessage } from './thumbnailsSlice'
import { OneDriveData, Thumbnail } from './types'

const onFileUploadProcessFailure = (
  dispatch: Dispatch<{
    payload: Alert
    type: string
  }>,
  id: number,
  fileName: string
) => {
  dispatch(
    addAlert({
      id: id,
      title: UploadFailureMessage(fileName),
      content: 'サイトが利用可能か確認して、もう一度やり直してください。',
      type: 'upload_error',
    })
  )

  dispatch(uploadAttachmentProcessOnRemove({ id: id }))
}

interface ByteRange {
  start: number
  end: number
}

const constructByteRanges = (
  fileSize: number,
  multipleLength: number
): ByteRange[] => {
  const pieceNum = Math.floor(fileSize / multipleLength)
  const pieces = []
  for (let i = 0; i < pieceNum + 1; i++) {
    const constVal = Math.min((i + 1) * multipleLength - 1, fileSize - 1)
    pieces.push({
      start: i * multipleLength,
      end: constVal,
    })
  }
  return pieces
}

// OneDriveにファイルをアップロードを実施します。
export const uploadFile =
  (
    data: string | ArrayBuffer,
    groupId: string,
    channelName: string,
    filename: string,
    requesterEmail: string,
    id: number | null,
    pastedImage: boolean
  ): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    // id is passed if it is pasted image
    if (id === null) {
      id = Math.random() * 10000
    }
    dispatch(
      uploadAttachmentProcessOnStart({
        id: id,
        filename: filename,
        progress: 6,
        pastedImage: pastedImage,
      })
    )

    // Step1 アップロード セッションを作成する, progress 0%-10%
    // https://docs.microsoft.com/en-us/onedrive/developer/rest-api/api/driveitem_createuploadsession?view=odsp-graph-online
    let createUploadSessionRequest: GraphRequest
    let createUploadSessionResponse: { uploadUrl: string }
    try {
      createUploadSessionRequest = graphAPI.api(
        `groups/${groupId}/drive/root:/${encodeURIComponent(
          channelName
        )}/${encodeURIComponent(filename)}:/createUploadSession`
      )
      createUploadSessionResponse = await createUploadSessionRequest.post({
        item: {
          '@microsoft.graph.conflictBehavior': 'rename',
        },
      })
    } catch (e) {
      catchException(
        e,
        dispatch,
        `failed at upload large files at creating upload session, filename:${filename}`
      )
      onFileUploadProcessFailure(dispatch, id, filename)
      // stop uploading this file
      return
    }
    if (!createUploadSessionResponse) {
      return
    }

    dispatch(uploadAttachmentProcessOnProgress({ id: id, progress: 10 }))

    const uploadUrl = createUploadSessionResponse.uploadUrl
    const uploadRequest = graphAPI.api(uploadUrl).header('content-type', 'text')

    const dataLength = (data as ArrayBuffer).byteLength //14315340

    // ファイルをtrunkに分割してある場合は,327680Byteの倍数にしないと行けないです.しかも、各trunkのサイズは60 * 1048576Byte(60Mib)を超えては行けません。
    // 15にしたら、一回のアップロードには4.6875MBのデータを送っています。
    // 小さくすると、リクスト数は増えます、大きくすると、progressの進捗は遅くなります。
    // バンランスをとって、15にします
    const multipleLength = 327680 * 15

    // Step2 アップロード セッションにバイトをアップロードする, process 0% - 80%
    // https://docs.microsoft.com/en-us/onedrive/developer/rest-api/api/driveitem_createuploadsession?view=odsp-graph-online#upload-bytes-to-the-upload-session
    const byteRanges = Array.from(
      constructByteRanges(dataLength, multipleLength)
    )
    let uploadResponse

    // 分割されたファイルのフラグメントは順番にアップロードされる必要があります。 誤った順序でアップロードすると、エラーが発生します。,Promise allは一気に全てのリクエストを投げますので、同期で行えないです。
    // n回アップロード、n = byteRanges.length
    for (let i = 0; i < byteRanges.length; i++) {
      // アップロード途中で取り消しています
      if (isFileUploadCanceled(getState, id)) {
        dispatch(deleteUploadSession(uploadUrl))
        return
      }
      const byteRange = byteRanges[i]
      try {
        uploadResponse = await uploadRequest
          .headers({
            'Content-Length': byteRange.end - byteRange.start + 1,
            'Content-Range': `bytes ${byteRange.start}-${byteRange.end}/${dataLength}`,
          })
          .put(data.slice(byteRange.start, byteRange.end + 1))
        const progress = 10 + ((i + 1) * 70) / byteRanges.length
        dispatch(
          uploadAttachmentProcessOnProgress({ id: id, progress: progress })
        )
      } catch (e) {
        catchException(
          e,
          dispatch,
          `failed at upload large files when upload No.${i} trunk file, filesize:${dataLength}, filename:${filename}`
        )
        onFileUploadProcessFailure(dispatch, id, filename)
        // stop uploading this file
        return
      }
    }

    if (!uploadResponse) {
      consoleErrorWithAirbrake(
        `failed at upload large files uploadResponse:${uploadResponse}, filesize:${dataLength}, filename:${filename}`
      )
      return
    }
    // n回のアップロードが終わってから、もう一度チャンセル済かどうかをチェックします
    // 例えば、アップロード回数がnの場合、第n回目の時にXボタンを押しました、第n回目のアップロード途中で止めれないですので、全部のn回アップロードが終わってから削除します
    const itemId = uploadResponse.id
    if (isFileUploadCanceled(getState, id)) {
      dispatch(deleteFile(groupId, itemId))
      return
    }

    const onEndData = {
      name: filename,
      itemId: itemId,
      uniqueId: '',
      downloadUrl: '',
      webUrl: '',
      mimeType: '',
      size: 0,
      driveId: '',
    }

    dispatch(
      uploadAttachmentOnEnd({
        id: id,
        data: onEndData,
      })
    )
    if (pastedImage) {
      dispatch(fetchImageToken(id, itemId))
    }

    dispatch(
      processUploadResponse(
        id,
        filename,
        groupId,
        channelName,
        uploadResponse,
        requesterEmail,
        pastedImage
      )
    )
  }

// ファイルアップロード途中でキャンセルした場合は、アップロードセッションも削除します。
export const deleteUploadSession =
  (uploadUrl: string): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    try {
      await graphAPI.api(uploadUrl).delete()
    } catch (e) {
      catchException(e, dispatch, `failed at deleting upload session`)
    }
  }

// 保存先(onedrive)からファイルを削除します。
export const deleteFile =
  (groupId: string, itemId: string): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    try {
      await graphAPI.api(`/groups/${groupId}/drive/items/${itemId}`).delete()
    } catch (e) {
      catchException(
        e,
        dispatch,
        `failed at deleting file with the groupId ${groupId} and itemId ${itemId}`
      )
    }
  }

// ファイルアップロードをキャンセルしたかどうかを判断します。
const isFileUploadCanceled = (getState: GetState, id: number): boolean => {
  // アップロード途中で取り消しています
  return (
    getState().messageAttachments.ids.filter((e: EntityId) => e === id)
      .length === 0
  )
}

interface CommonUploadResponse {
  id: string
  name: string
  webUrl: string
  file: UploadResponseFile
  size: number
  parentReference: ParentReference
}

interface ParentReference {
  driveId: string
}

interface UploadResponseFile {
  mimeType: string
}

declare type UploadFileResponse = CommonUploadResponse & {
  '@microsoft.graph.downloadUrl': string
}

declare type UploadLargeFileResponse = CommonUploadResponse & {
  '@content.downloadUrl': string
}

// 保存先(OneDrive)にアップロード済んだ後の処理を実施します。
const processUploadResponse =
  (
    id: number,
    filename: string,
    groupId: string,
    channelName: string,
    uploadResponse: UploadFileResponse | UploadLargeFileResponse,
    requesterEmail: string,
    pastedImage: boolean
  ): AppThunk =>
  async (dispatch, getState, { graphAPI }) => {
    const itemId = uploadResponse.id
    const fileType = uploadResponse.file.mimeType
    const webUrl = uploadResponse.webUrl
    const downLoadUrl = (uploadResponse as UploadLargeFileResponse)[
      '@content.downloadUrl'
    ]

    if (!downLoadUrl) {
      return
    }

    const downloadUrlObj = new URL(downLoadUrl)
    const uniqueId = downloadUrlObj.searchParams.get('UniqueId')
    // extract uniqueID used as bot framework parameter
    if (!uniqueId) {
      return
    }

    // Step2. Construct contentUrl
    // contentUrl is used to send file through bot framework api.
    // if the file is an image file, we should replace it with download url
    let contentUrl = webUrl

    // Step2-1. Deal with Office file
    // If the file is an office file, generate the original file path
    // This might be a microsoft office file, which is not a direct file url, but an online office edit url.
    // Only the file direct path or the download link can be used to send a file to a user though bot framework api.
    // If normal downloadUrl is used to send bot,it will show file is not safe at requester side.
    // アップロード途中で取り消しています
    if (isFileUploadCanceled(getState, id)) {
      return
    }
    const regExpMSFile = new RegExp(/(https:.*?)_layouts.*/)
    const resMsFile = regExpMSFile.exec(webUrl)
    if (resMsFile && resMsFile.length >= 1) {
      contentUrl = `${resMsFile[1]}Shared%20Documents/${channelName}/${uploadResponse.name}`
    }

    // Step3: create shareLink for the uploaded file.
    // ShareLink is used to provide a file management url to be accessed.
    // For the operator would not be the file's owner, webUrl(which is the original operator's file) could be not access by other operators.
    // We need to provide a organizational public url to let other operators be able to access the file.
    // https://docs.microsoft.com/en-us/graph/api/driveitem-createlink?view=graph-rest-1.0&tabs=http
    // アップロード途中で取り消しています
    if (isFileUploadCanceled(getState, id)) {
      return
    }
    const shareLinkRequest = graphAPI.api(
      `/groups/${groupId}/drive/items/${itemId}/createLink`
    )
    let shareLinkResponse
    try {
      shareLinkResponse = await shareLinkRequest.post({
        type: 'view',
        scope: 'organization',
      })
    } catch (e) {
      if (e.statusCode === 404 && isFileUploadCanceled(getState, id)) {
        return
      }
      catchException(
        e,
        dispatch,
        `failed at generating share link with the groupId ${groupId} and itemId ${itemId}. error: ${JSON.stringify(
          e
        )}`
      )
      onFileUploadProcessFailure(dispatch, id, filename)
      return
    }

    dispatch(uploadAttachmentProcessOnProgress({ id: id, progress: 90 }))

    const shareLink = shareLinkResponse.link.webUrl

    // Step4. Create thumbnail for the file, if the file is an preview-image file.
    // Thumbnail url is used to show image's preview on operator page.
    // For image's preview,we need a direct file url. But webUrl has privilege limitation. Download Url has a expire limitation.
    // The only way to show a image file's preview is by using its thumb url.
    // https://docs.microsoft.com/en-us/graph/api/driveitem-list-thumbnails?view=graph-rest-1.0&tabs=http
    // アップロード途中で取り消しています
    if (isFileUploadCanceled(getState, id)) {
      return
    }

    // Deal with Attachment Image or Paste Image(That should be equipped with preview functionality)
    if (isImage(fileType) || pastedImage) {
      let thumbnails: { [name: string]: Thumbnail } | null = null
      const thumbNailRequest = graphAPI.api(
        `/groups/${groupId}/drive/items/${itemId}/thumbnails`
      )
      let thumbNailResponse
      try {
        thumbNailResponse = await thumbNailRequest.get()
      } catch (e) {
        if (e.statusCode === 404 && isFileUploadCanceled(getState, id)) {
          return
        }

        catchException(
          e,
          dispatch,
          `failed at generating thumbnail with the groupId ${groupId} and itemId ${itemId}. error: ${JSON.stringify(
            e
          )}`
        )
        onFileUploadProcessFailure(dispatch, id, filename)
        return
      }

      const { large, medium, small } = thumbNailResponse.value[0]
      // If we send a image with preview, we must send it using thumbnailURL. downloadUrl seems not to be supported anymore.
      // If contentURL is used to send to bot, it will get Attached content url is not valid error on requester side.
      contentUrl = [large.url, medium.url, small.url].find((e) => e)

      // For paste image, we need to know the size of the thumbnail image.
      if (pastedImage) {
        thumbnails = {
          small: {
            url: small.url,
            width: small.width,
            height: small.height,
          },
          medium: {
            url: medium.url,
            width: medium.width,
            height: medium.height,
          },
          large: {
            url: large.url,
            width: large.width,
            height: large.height,
          },
        }

        dispatch(
          fetchThumbnailOnMessage({
            itemId: itemId,
            thumbnails: thumbnails,
          })
        )
        const size = await getSrcImageInfo(large.url)
        dispatch(
          uploadAttachmentOnThumbnailSizedFetched({ id: id, size: size })
        )
      }
    }

    dispatch(uploadAttachmentProcessOnProgress({ id: id, progress: 95 }))

    // Step5. give permission to requester
    // Requester has to open file sent from operator
    // However, if the operator's is in a private team, requester user do not have privilege to access contentUrl provided from operator.
    // So operator should add view privilege to requester, so the requester user can view the file on Teams App.
    // https://docs.microsoft.com/en-us/graph/api/driveitem-invite?view=graph-rest-1.0&tabs=http
    // アップロード途中で取り消しています
    if (isFileUploadCanceled(getState, id)) {
      return
    }
    const addPermissionRequest = graphAPI.api(
      `groups/${groupId}/drive/items/${itemId}/invite`
    )
    try {
      await addPermissionRequest.post({
        recipients: [
          {
            email: requesterEmail,
          },
        ],
        message: 'オペレーターからのファイルです',
        RequireSignIn: true,
        sendInvitation: false,
        roles: ['read'],
      })
    } catch (err: unknown) {
      const e = err as GraphError
      if (e.statusCode === 404 && isFileUploadCanceled(getState, id)) {
        return
      }

      catchException(
        e,
        dispatch,
        `failed at adding permission with groupId ${groupId} and itemId ${itemId}. error: ${JSON.stringify(
          e
        )}`
      )
      onFileUploadProcessFailure(dispatch, id, filename)
      return
    }

    dispatch(uploadAttachmentProcessOnProgress({ id: id, progress: 98 }))

    // Step6.Update the data, render the page's content
    // アップロード途中で取り消しています
    if (isFileUploadCanceled(getState, id)) {
      return
    }

    const onEndData: OneDriveData = {
      name: uploadResponse.name,
      itemId: itemId,
      uniqueId: uniqueId,
      downloadUrl: contentUrl,
      webUrl: shareLink,
      mimeType: fileType,
      size: uploadResponse.size,
      driveId: uploadResponse.parentReference.driveId,
    }

    dispatch(
      uploadAttachmentProcessOnEnd({
        file: {
          id: id,
          attachmentData: onEndData,
          progress: 100,
        },
      })
    )
  }

const UploadFailureMessage = (fileName: string) =>
  `ファイル ${fileName} はアップロードされませんでした。`

const catchException = (
  e: GraphError,
  dispatch: Dispatch<{
    payload: undefined
    type: string
  }>,
  title: string
) => {
  if (e.statusCode === 401) {
    dispatch(resetTokens())
    return
  }

  // https://linear.app/pksha-desk/issue/PKS-1691/[airbrake-desk-client-workplace]-failed-at-adding-permission-with
  // Don't catch exception at the case when the requester users is deleted,
  const isNoResolvedUsersErr =
    e.statusCode === 400 && e.code === 'noResolvedUsers'
  if (isNoResolvedUsersErr) {
    return
  }

  consoleErrorWithAirbrake(`${title}: ${e}`)
}

export const extractChannelName = (
  channelRelativeUrl: string | undefined
): string | undefined => {
  if (!channelRelativeUrl) {
    return undefined
  }

  if (!channelRelativeUrl.includes('/')) {
    return undefined
  }

  //channelRelateUrlの最後の使います
  return channelRelativeUrl.split('/').pop()
}

export const onChannelNameNotFound = (
  dispatch: Dispatch<{
    payload: Alert
    type: string
  }>,
  groupId: string,
  channelId?: string,
  uploadFolder?: string,
  channelRelativeUrl?: string
): void => {
  // show alert
  dispatch(
    addAlert({
      id: Math.random() * 1000,
      title: 'ファイルのアップロードに失敗しました',
      content: 'しばらくお待ちいただいてから、もう一度お試しください。',
      type: 'upload_error',
    })
  )
  // airbrake
  consoleErrorWithAirbrake(
    `channelName is lack of, groupId:${groupId},channelId:${channelId}, uploadFolder:${uploadFolder}, channelRelativeUrl:${channelRelativeUrl}`
  )
}

const getSrcImageInfo = (
  src: string
): Promise<{ width: number; height: number }> => {
  const img = new Image()
  img.src = src
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    try {
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        })
      }
    } catch (e) {
      reject(e.toString())
    }
  })
}
