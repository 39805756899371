import { Box, Button, Flex, Text } from '@fluentui/react-northstar'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogWithCloseIcon from '../../components/DialogWithCloseIcon'
import styles from './NotificationSettingDialog.module.css'
import {
  fetchNewTicketNotificationUsers,
  notificationSetttingSelector,
  updateNewTicketNotificationUsers,
} from './notificationSettingSlice'

interface Props {
  open: boolean
  renderAssigneeSelection: (
    values: string[],
    onChange: (value: string[]) => void
  ) => React.ReactElement
  onCancel: () => void
  onConfirm: () => void
}

const NotificationDialog: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const [
    selectedNewTicketNotificationUserIds,
    setSelectedNewTicketNotificationUserIds,
  ] = useState<string[]>([])
  const notificationSettings = useSelector(
    notificationSetttingSelector.selectAll
  )

  // 既存の通知設定をDropBoxに反映させます
  useEffect(() => {
    setSelectedNewTicketNotificationUserIds(
      notificationSettings.map((n) => n.userId)
    )
  }, [notificationSettings])

  // 更新ボタンを押したタイミング
  const handleUpdate = () => {
    dispatch(
      updateNewTicketNotificationUsers(selectedNewTicketNotificationUserIds)
    )
    props.onConfirm()
  }

  // ダイアログを開くときにリクエスト
  useEffect(() => {
    if (!props.open) {
      return
    }
    dispatch(fetchNewTicketNotificationUsers()) // 最新のメンションユーザーの情報を取得します
  }, [dispatch, props.open])

  return (
    <DialogWithCloseIcon
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      content={
        <Box>
          <h3>新規問い合わせの通知</h3>
          <Box>
            <Text className={styles.notificationSettingDialogHeader}>
              下記リストに追加すると、新しい問い合わせを受け付けた際に、通知を受けることができます。
            </Text>
          </Box>
          <Flex className={styles.notificationSettingDialogContent}>
            {props.renderAssigneeSelection(
              selectedNewTicketNotificationUserIds,
              setSelectedNewTicketNotificationUserIds
            )}
          </Flex>
        </Box>
      }
      footer={
        <Button primary onClick={handleUpdate}>
          更新
        </Button>
      }
      header="通知設定"
      className={styles.dialog}
    />
  )
}

export default NotificationDialog
