import {
  Box,
  Button,
  ChatItemProps,
  Flex,
  Chat as FluentChat,
  Ref,
  Text,
} from '@fluentui/react-northstar'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { TicketStatus, Tickets } from '../../consts'
import message_pb from '../../proto/message_pb'
import { consoleErrorWithAirbrake, unique } from '../../utils'
import { fetchMembers } from '../auth/memberSlice'
import { fetchUserPhotos } from '../auth/userPhotosSlice'
import { fetchUsers } from '../auth/usersSlice'
import { onChatLayoutFetched } from '../layout/layoutSlice'
import { Ticket, toggleShowAllAutoMessage } from '../ticket/ticketSlice'
import styles from './Chat.module.css'
import ChatInput from './ChatInput'
import {
  chatItemsSelector,
  closeAllTimers,
  fetchMessages,
  messagesSelectors,
  removeAllMessages,
} from './messagesSlice'
import { TextHtmlData } from './types'

export interface ChatProps {
  ticket: Ticket
  onClickFaqCreate: () => void
}

const Chat: React.FC<ChatProps> = ({ ticket, onClickFaqCreate }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const ref = React.useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const authState = useSelector((state: RootState) => state.auth)
  const messages = useSelector(messagesSelectors.selectAll)
  const messagesState = useSelector((state: RootState) => state.messages)

  const renderPastAutoChatToggle = React.useCallback(
    (showAllAutoMessage: boolean, ticketId: number) => (
      <PastAutoChatToggleComponent
        showAllAutoMessage={showAllAutoMessage}
        ticketId={ticketId}
      />
    ),
    []
  )

  const chatItems = useSelector((state: RootState) =>
    chatItemsSelector(state, ticket, renderPastAutoChatToggle)
  )

  React.useEffect(() => {
    dispatch(fetchMembers())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(fetchMessages(ticket.id))
    return () => {
      dispatch(removeAllMessages())
      dispatch(closeAllTimers())
    }
  }, [
    dispatch,
    ticket.id,
    messagesState.includesActivityLog,
    messagesState.excludesEvent,
  ])

  React.useEffect(() => {
    if (ticket.requesterType === Tickets.RequesterType.RequesterTypeWebagent)
      return

    dispatch(fetchUsers([ticket.requesterUserId]))
    dispatch(fetchUserPhotos([ticket.requesterUserId]))
  }, [dispatch, ticket.requesterUserId, ticket.requesterType])

  React.useEffect(() => {
    const ticketIds = unique(messages.map((m) => m.ticketId))
    if (
      messages.length === 0 ||
      ticketIds.length != 1 ||
      ticketIds[0] != ticket.id
    )
      return

    const userIds = messages.map((m) => m.userId)
    if (ticket.requesterType != Tickets.RequesterType.RequesterTypeWebagent) {
      dispatch(fetchUsers(userIds))
      dispatch(fetchUserPhotos(userIds))
    }
    setTimeout(
      (innerRef) => {
        if (innerRef.current === null) return
        innerRef.current.scrollTo(0, innerRef.current.scrollHeight)
      },
      100,
      ref
    )
  }, [dispatch, messages, ref, ticket])

  React.useEffect(() => {
    dispatch(
      onChatLayoutFetched({
        h: containerRef?.current?.clientHeight || 0,
        w: containerRef?.current?.clientWidth || 0,
      })
    )
  }, [
    dispatch,
    containerRef?.current?.clientHeight,
    containerRef?.current?.clientWidth,
  ])

  return (
    <Ref innerRef={containerRef}>
      <Flex column className={styles.container}>
        <Ref innerRef={ref}>
          <ChatWrap chatItems={chatItems} />
        </Ref>
        <Box className={styles.footer}>
          {ticket.status === TicketStatus.completed ? (
            <>
              {authState.deskAvailableFeatures?.faq ? (
                <Flex gap="gap.large" hAlign="center" vAlign="center">
                  <Button
                    onClick={() =>
                      onClickFaqCreate ? onClickFaqCreate() : null
                    }
                    disabled={ticket.faqCreated}
                    primary
                  >
                    FAQ作成
                  </Button>
                </Flex>
              ) : (
                <></>
              )}
            </>
          ) : (
            <ChatInput />
          )}
        </Box>
      </Flex>
    </Ref>
  )
}

type ChatWrapProps = {
  chatItems: ChatItemProps[]
}
const ChatWrap = React.memo<ChatWrapProps>((props) => {
  return (
    <Box className={styles.main}>
      <FluentChat
        className={styles.fluentChat}
        items={props.chatItems}
        styles={{
          backgroundColor: 'transparent',
        }}
      />
    </Box>
  )
})
ChatWrap.displayName = 'ChatWrap'

const PastAutoChatToggleComponent: React.FC<{
  showAllAutoMessage: boolean
  ticketId: number
}> = (props) => {
  const { showAllAutoMessage, ticketId } = props
  const dispatch = useDispatch()

  const handleToggle = React.useCallback(
    (ticketId: number) => {
      dispatch(toggleShowAllAutoMessage({ id: ticketId }))
    },
    [dispatch]
  )

  return (
    <div
      className={styles.toggleAllAutoDisplayButton}
      key="pastAutoChatToggleButton"
    >
      <Flex hAlign="center" onClick={() => handleToggle(ticketId)}>
        <div
          className={[
            styles.toggleAllAutoDisplayButtonArrow,
            styles.toggleAllAutoDisplayButtonText,
          ].join(' ')}
        >
          {showAllAutoMessage ? '↓' : '↑'}
        </div>
        <Box>
          <Text className={styles.toggleAllAutoDisplayButtonText}>
            {showAllAutoMessage
              ? '最新の自動応答履歴のみ表示'
              : '過去100件までの自動応答履歴を表示'}
          </Text>
        </Box>
      </Flex>
    </div>
  )
}

export const parseRichText = (
  data?: message_pb.ResponseAttachment.AsObject
): string | null => {
  if (data == null) {
    return null
  }
  try {
    const textHtmlAttachment = JSON.parse(data.data) as TextHtmlData
    return textHtmlAttachment.content
  } catch (e) {
    consoleErrorWithAirbrake(
      `failed at parsing TextHtmlData type data at createChatItem, error: ${e}, attachment data: ${data.data}`
    )
  }
  return null
}

export default Chat
