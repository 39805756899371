import {
  BatchRequestContent,
  BatchResponseContent,
  OneDriveLargeFileUploadTask,
} from '@microsoft/microsoft-graph-client'
import {
  AadUserConversationMember,
  DriveItem,
  Permission,
  ThumbnailSet,
  User,
} from '@microsoft/microsoft-graph-types'
import { EntityState, createEntityAdapter } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import { splitArray } from '../../utils'
import { graphBaseQuery } from './lib/graphBaseQuery'

export type Member = {
  id: string
  name: string
  email: string
}

export const memberEntityAdapter = createEntityAdapter<Member>()

const aadConversationMemberToMember = (
  member: AadUserConversationMember
): Member => ({
  id: member.id ?? '',
  name: member.displayName ?? '',
  email: member.email ?? '',
})

type TeamsCustomApplication = {
  displayName: string
  iconURL: string
}

interface TeamsApps {
  value: TeamsApp[]
}

interface TeamsApp {
  id: string
  displayName: string
  externalId: string
  distributionMethod: string
  appDefinitions: AppDefinition[]
}

interface AppDefinition {
  id: string
  teamsAppId: string
  displayName: string
  description: string
  version: string
  publishingState: string
  shortDescription: string
  colorIcon: ColorIcon
}

interface ColorIcon {
  id: string
  webUrl: string
}

const MaxBatchSize = 20

export const betaGraphApi = createApi({
  reducerPath: 'betaGraphApi',
  baseQuery: graphBaseQuery({ useBeta: true }),
  endpoints: (build) => ({
    getTeamsCustomApplicationInfo: build.query<
      TeamsCustomApplication,
      { externalId: string }
    >({
      query: (args) => ({
        request: async (client) => {
          // see: https://learn.microsoft.com/ja-jp/graph/api/teamsappicon-get?view=graph-rest-beta&tabs=http&viewFallbackFrom=graph-rest-1.0
          const teamsApps: TeamsApps = await client
            .api(
              `/appCatalogs/teamsApps?$filter=externalId eq '${args.externalId}'`
            )
            .expand('appDefinitions($expand=colorIcon)')
            .get()
          if (
            teamsApps.value.length === 0 ||
            teamsApps.value[0].appDefinitions.length === 0
          ) {
            return {
              displayName: '',
              iconURL: '',
            }
          }
          const appDefinition = teamsApps.value[0].appDefinitions[0]
          const iconImage: Blob = await client
            .api(
              appDefinition.colorIcon.webUrl.replace(
                'https://graph.microsoft.com/beta',
                ''
              )
            )
            .get()
          if (iconImage.size === 0) {
            return {
              displayName: appDefinition.displayName,
              iconURL: '',
            }
          }
          const iconURL = URL.createObjectURL(iconImage)
          return {
            displayName: appDefinition.displayName,
            iconURL,
          }
        },
      }),
    }),
    getProfileMeBeta: build.query<User, void>({
      query: () => ({
        request: async (client) => client.api(`/me`).get(),
      }),
      transformResponse: (response: User) => {
        return response
      },
    }),
  }),
})

export const graphApi = createApi({
  reducerPath: 'graphApi',
  baseQuery: graphBaseQuery({ useBeta: false }),
  endpoints: (build) => ({
    getMember: build.query<Member, { id: string }>({
      query: (args) => ({
        request: async (client) => client.api(`/users/${args.id}`).get(),
      }),
      transformResponse: aadConversationMemberToMember,
    }),
    getProfileMe: build.query<Member, void>({
      query: () => ({
        request: async (client) => client.api(`/me`).get(),
      }),
      transformResponse: aadConversationMemberToMember,
    }),
    getMemberPhoto: build.query<string, { id: string }>({
      query: (args) => ({
        request: async (client) => {
          if (args.id === '') {
            return null
          } else {
            return window.URL.createObjectURL(
              // 48x48のRetina対応で2倍して 96x96 を取得する
              await client.api(`/users/${args.id}/photos/96x96/$value`).get()
            )
          }
        },
      }),
    }),
    getMembersWithFilter: build.query<EntityState<Member>, { query: string }>({
      query: (args) => ({
        request: async (client) => {
          return await client
            .api(
              `/users?$select=displayName,id,mail&$filter=startsWith(displayName,'${args.query}') or startsWith(mail,'${args.query}')`
            )
            .get()
        },
      }),
      transformResponse(result: {
        value: { id: string; displayName: string; mail: string }[]
      }): EntityState<Member> {
        return memberEntityAdapter.setAll(
          memberEntityAdapter.getInitialState(),
          result.value.map<Member>((m) => ({
            id: m.id,
            name: m.displayName,
            email: m.mail,
          }))
        )
      },
    }),
    getMembersWithIDs: build.query<EntityState<Member>, { ids: string[] }>({
      query: (args) => ({
        request: async (client) => {
          return await client
            .api(
              `/users?$select=displayName,id,mail&$filter=${args.ids
                .map((id) => `id eq '${id}'`)
                .join(' or ')}`
            )
            .get()
        },
      }),
      transformResponse(result: {
        value: { id: string; displayName: string; mail: string }[]
      }): EntityState<Member> {
        return memberEntityAdapter.setAll(
          memberEntityAdapter.getInitialState(),
          result.value.map<Member>((m) => ({
            id: m.id,
            name: m.displayName,
            email: m.mail,
          }))
        )
      },
    }),
    getMembersByBatch: build.query<EntityState<Member>, { ids: string[] }>({
      query: (args) => ({
        request: async (client): Promise<User[]> => {
          const batches = splitArray(args.ids, MaxBatchSize)

          return (
            await Promise.all(
              batches.map(async (bat) => {
                const batchRequestContent = new BatchRequestContent(
                  bat.map((id) => ({
                    id: id,
                    request: new Request(
                      `/users/${id}?$select=id,mail,displayName`,
                      {
                        method: 'GET',
                      }
                    ),
                  }))
                )
                const content = await batchRequestContent.getContent()
                const result = await client.api('/$batch').post(content)

                return (await Promise.all(
                  Array.from(
                    new BatchResponseContent(result).getResponses().values()
                  )
                    .map((r) => {
                      if (!r.ok) {
                        return null
                      }
                      return r.json()
                    })
                    .filter(isNotNull)
                )) as User[]
              })
            )
          ).flatMap((a) => a)
        },
      }),
      transformResponse(result: User[]): EntityState<Member> {
        return memberEntityAdapter.setAll(
          memberEntityAdapter.getInitialState(),
          result.map((m) => ({
            id: m.id ?? '',
            name: m.displayName ?? '',
            email: m.mail ?? '',
          }))
        )
      },
    }),
    uploadFile: build.mutation<
      { driveId: string; itemId: string; webUrl: string; uniqueId: string },
      { file: File; folderName: string }
    >({
      query: (args) => ({
        request: async (client) =>
          await client
            .api(
              `/me/drive/root:/${args.folderName}/${args.file.name}:/content?@microsoft.graph.conflictBehavior=rename`
            )
            .put(args.file),
      }),
      transformResponse: (
        res: DriveItem & { '@microsoft.graph.downloadUrl': string }
      ) => {
        const downloadUrl = res['@microsoft.graph.downloadUrl']
        const downloadUrlObj = new URL(downloadUrl)
        const uniqueId = downloadUrlObj.searchParams.get('UniqueId')
        if (!res.parentReference?.driveId) {
          throw new Error('graph api not returns driveId')
        }
        return {
          driveId: res.parentReference?.driveId ?? '',
          itemId: res.id ?? '',
          webUrl: res.webUrl ?? '',
          uniqueId: uniqueId ?? '',
        }
      },
    }),
    uploadLargeFile: build.mutation<
      { driveId: string; itemId: string; uniqueId: string; webUrl: string },
      { file: File; folderName: string }
    >({
      query: (args) => ({
        request: async (client) => {
          const uploadTask: OneDriveLargeFileUploadTask =
            await OneDriveLargeFileUploadTask.create(client, args.file, {
              path: args.folderName,
              fileName: args.file.name,
            })
          return await uploadTask.upload()
        },
      }),
      transformResponse: (
        item: DriveItem & { '@content.downloadUrl': string }
      ) => {
        const downloadUrl = item['@content.downloadUrl']
        const downloadUrlObj = new URL(downloadUrl)
        const uniqueId = downloadUrlObj.searchParams.get('UniqueId')
        if (!item.parentReference?.driveId) {
          throw new Error('graph api not returns driveId')
        }
        return {
          driveId: item.parentReference?.driveId ?? '',
          itemId: item.id ?? '',
          uniqueId: uniqueId ?? '',
          webUrl: item.webUrl ?? '',
        }
      },
    }),
    uploadFileToGroup: build.mutation<
      { itemID: string },
      { file: File; groupID: string }
    >({
      query: (arg) => ({
        request: async (client) =>
          await client
            .api(
              `/groups/${arg.groupID}/drive/items/root:/${arg.file.name}:/content?@microsoft.graph.conflictBehavior=rename`
            )
            .put(arg.file),
      }),
      transformResponse(
        result: DriveItem & { '@microsoft.graph.downloadUrl': string }
      ) {
        return {
          itemID: result.id ?? '',
        }
      },
    }),
    uploadLargeFileToGroup: build.mutation<
      { itemID: string },
      { file: File; groupID: string }
    >({
      query: (arg) => ({
        request: async (client) => {
          const uploadTask = await OneDriveLargeFileUploadTask.create(
            client,
            arg.file,
            {
              path: '/',
              fileName: arg.file.name,
            }
          )
          return await uploadTask.upload()
        },
      }),
      transformResponse(
        result: DriveItem & { '@microsoft.graph.downloadUrl': string }
      ) {
        return {
          itemID: result.id ?? '',
        }
      },
    }),
    createShareLinkFromGroup: build.mutation<
      { url: string },
      { itemID: string; groupID: string }
    >({
      query: (arg) => ({
        request: async (client) =>
          await client
            .api(`/groups/${arg.groupID}/drive/items/${arg.itemID}/createLink`)
            .post({
              type: 'view',
              scope: 'organization',
              expirationDateTime: '2100-01-01T00:00:00Z',
            }),
      }),
      transformResponse(result: Permission) {
        return {
          url: result.link?.webUrl ?? '',
        }
      },
    }),
    getThumbnail: build.mutation<string, { driveId: string; itemId: string }>({
      query: (args) => ({
        request: (client) =>
          client
            .api(`/drives/${args.driveId}/items/${args.itemId}/thumbnails`)
            .get(),
      }),
      transformResponse: (res: { value: ThumbnailSet[] }) => {
        return res.value[0].large?.url ?? ''
      },
    }),
    getShareLink: build.mutation<
      Permission,
      { driveId: string; itemId: string }
    >({
      query: (args) => ({
        request: (client) =>
          client
            .api(`/drives/${args.driveId}/items/${args.itemId}/createLink`)
            .post({
              type: 'view',
              scope: 'organization',
              expirationDateTime: '2100-01-01T00:00:00Z',
            }),
      }),
    }),
    removeFile: build.mutation<void, { driveId: string; itemId: string }>({
      query: (args) => ({
        request: (client) =>
          client.api(`/drives/${args.driveId}/items/${args.itemId}`).delete(),
      }),
    }),
    removeFileFromGroup: build.mutation<
      void,
      { groupID: string; itemID: string }
    >({
      query: (args) => ({
        request: (client) =>
          client
            .api(`/groups/${args.groupID}/drive/items/${args.itemID}`)
            .delete(),
      }),
    }),
  }),
})

function isNotNull<T>(value: T | null): value is T {
  return value !== null
}

export const {
  useGetMemberQuery,
  useGetMembersWithFilterQuery,
  useGetMembersWithIDsQuery,
  useGetMemberPhotoQuery,
  useGetProfileMeQuery,
  useUploadFileMutation,
  useGetMembersByBatchQuery,
} = graphApi

export const {
  useGetTeamsCustomApplicationInfoQuery,
  useGetProfileMeBetaQuery,
} = betaGraphApi
