import { ReplyIcon } from '@fluentui/react-icons-northstar'
import { Avatar, Text } from '@fluentui/react-northstar'
import * as React from 'react'

import RequesterChatFileList, {
  FileProp,
} from '../../../../components/RequesterChatFileList'
import styles from './style.module.css'

type Props = {
  memberName: string
  avatarImage: string
  time: string
  replyCount: number
  files: FileProp[]
  isLinked: boolean
  showLastReply: boolean
  renderReplyList: () => React.ReactElement
  renderLastReply: () => React.ReactElement
  renderChatText: () => React.ReactElement
  renderChatInput: (onClickClose: () => void) => React.ReactElement
  showTicketReplies: boolean
  handleShowReplies: (open: boolean) => void
  replyInputDefaultOpen?: boolean
}

const RequesterTicketItemPresenter: React.FC<Props> = (props) => {
  const { replyInputDefaultOpen } = props
  const { showTicketReplies, handleShowReplies } = props
  const [isShowReplyInput, setIsShowReplyInput] = React.useState(
    replyInputDefaultOpen ?? false
  )

  return (
    <div className={styles.container}>
      <div className={styles.iconBox}>
        <Avatar
          size="large"
          image={props.avatarImage}
          name={props.memberName}
        />
      </div>
      <div className={styles.messageBox}>
        <div className={styles.messageTextBox}>
          <div className={styles.infoRow}>
            <Text size="small" weight="bold">
              {props.memberName}
            </Text>
            <Text className={styles.infoTime} size="small">
              {props.time}
            </Text>
          </div>
          <Text className={styles.messageBody}>{props.renderChatText()}</Text>
          {props.files.length > 0 && (
            <RequesterChatFileList
              className={styles.fileList}
              files={props.files}
            />
          )}
        </div>
        <div className={styles.messageReplyBox}>
          {props.replyCount >= 1 && (
            <div
              onClick={() => {
                handleShowReplies(!showTicketReplies)
              }}
              className={styles.messageReplyControl}
            >
              {showTicketReplies ? (
                <Text size="small">すべて折りたたむ</Text>
              ) : (
                <Text size="small">{props.replyCount} 件の返信</Text>
              )}
            </div>
          )}
          <div className={styles.messageReplyList}>
            {/*// 返信を表示しているときは最後の返信を表示しない*/}
            {showTicketReplies
              ? props.renderReplyList()
              : props.showLastReply && props.renderLastReply()}
          </div>
          {isShowReplyInput ? (
            <div className={styles.replyChatInput}>
              {props.renderChatInput(() => setIsShowReplyInput(false))}
            </div>
          ) : (
            <div
              className={styles.messageReplyButton}
              onClick={() => setIsShowReplyInput(true)}
            >
              <ReplyIcon size="small" />
              <Text className={styles.messageReplyBoxText} size="small">
                返信
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RequesterTicketItemPresenter
