import {
  Checkbox,
  Flex,
  Input,
  Loader,
  Text,
  TextArea,
} from '@fluentui/react-northstar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../app/store'
import { Dropdown } from '../../components/Dropdown'
import { addAlert } from '../alert/alertsSlice'
import styles from './FaqForm.module.css'
import { FaqLabelSelector, getFaqLabels } from './faqLabelSlice'
import { FaqStateErrorObject } from './faqSlice'

export interface FaqFormProps {
  disabled: boolean
  requestValue: string
  setRequestValue: (value: string) => void
  responseValue: string
  setResponseValue: (value: string) => void
  publish: boolean
  setPublish: (value: boolean) => void
  name: string
  setName?: (value: string) => void
  labels: string[]
  setLabels: (value: string[]) => void
}

const FaqForm: React.FC<FaqFormProps> = (props: FaqFormProps) => {
  const dispatch = useDispatch()

  const faqState = useSelector((state: RootState) => state.faq)
  const faqLabelState = useSelector((state: RootState) => state.faqLabel)

  const faqLabels = useSelector(FaqLabelSelector.selectAll)
  const [faqLabelItems, setFaqLabelItems] = useState<
    { header: string; value: string }[]
  >([])

  useEffect(() => {
    dispatch(getFaqLabels())
  }, [dispatch])

  useEffect(() => {
    setFaqLabelItems(
      faqLabels.map((it) => {
        return { header: it.value, value: it.id.toString() }
      })
    )
  }, [faqLabels])

  useEffect(() => {
    if (faqLabelState.error === true) {
      dispatch(
        addAlert({
          id: Math.random() * 1000,
          title: 'ラベルの取得に失敗しました。',
          type: 'faq_label_error',
        })
      )
    }
  }, [dispatch, faqLabelState.error])

  return (
    <>
      <Flex column gap="gap.smaller">
        <Text weight="bold">
          FAQの質問&nbsp;<Text color="red">*</Text>
        </Text>
        <TextArea
          disabled={props.disabled}
          placeholder={'FAQの質問'}
          className={styles.inputField}
          required
          rows={4}
          resize="vertical"
          value={props.requestValue}
          onChange={(e) =>
            props.setRequestValue((e.target as HTMLTextAreaElement).value)
          }
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="bold">
          FAQの回答&nbsp;<Text color="red">*</Text>
        </Text>
        <TextArea
          disabled={props.disabled}
          placeholder={'FAQの回答'}
          className={styles.inputField}
          required
          rows={9}
          resize="vertical"
          value={props.responseValue}
          onChange={(e) =>
            props.setResponseValue((e.target as HTMLTextAreaElement).value)
          }
        />
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="bold">FAQ識別子（インテント識別子）</Text>
        <Input
          fluid
          disabled={!props.setName}
          placeholder={'未入力の場合は自動で設定されます'}
          className={styles.inputField}
          value={props.name}
          onChange={(e) => {
            if (props.setName)
              props.setName((e.target as HTMLInputElement).value)
          }}
        />
        {FaqStateErrorObject.isFieldViolationError(faqState.error, 'name') ? (
          <Text align={'start'} size="small" color="red">
            {faqState.error?.message}
          </Text>
        ) : (
          ''
        )}
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="bold">分類ラベル</Text>
        {faqLabelState.loading ? (
          <Loader size="small" />
        ) : props.disabled ? (
          <div className={styles.disabledLabelContainer}>
            {props.labels.map((s) => (
              <div key={s} className={styles.disabledLabelItem}>
                {faqLabelItems.find((a) => a.value == s)?.header}
              </div>
            ))}
          </div>
        ) : (
          <Dropdown
            fluid
            multiple
            search
            placeholder={'選択してください'}
            noResultsMessage={'選択肢がありません'}
            className={styles.inputField}
            value={props.labels}
            onValueChange={(values) => props.setLabels(values)}
            items={faqLabelItems}
          />
        )}
      </Flex>
      <Flex column gap="gap.smaller">
        <Text weight="bold">FAQの公開設定</Text>
        <Checkbox
          disabled={props.disabled}
          checked={props.publish}
          onChange={(_, value) => props.setPublish(value?.checked == true)}
          label="公開する"
        />
      </Flex>
    </>
  )
}

export default FaqForm
