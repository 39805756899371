// package: 
// file: proto/faq.proto

var proto_faq_pb = require("../proto/faq_pb");
var proto_ticket_pb = require("../proto/ticket_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FaqAPI = (function () {
  function FaqAPI() {}
  FaqAPI.serviceName = "FaqAPI";
  return FaqAPI;
}());

FaqAPI.CreateFaq = {
  methodName: "CreateFaq",
  service: FaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_faq_pb.CreateFaqRequest,
  responseType: proto_ticket_pb.Ticket
};

FaqAPI.GetFaq = {
  methodName: "GetFaq",
  service: FaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_faq_pb.GetFaqRequest,
  responseType: proto_faq_pb.Faq
};

FaqAPI.UpdateFaq = {
  methodName: "UpdateFaq",
  service: FaqAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_faq_pb.UpdateFaqRequest,
  responseType: proto_faq_pb.Faq
};

exports.FaqAPI = FaqAPI;

function FaqAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FaqAPIClient.prototype.createFaq = function createFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FaqAPI.CreateFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FaqAPIClient.prototype.getFaq = function getFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FaqAPI.GetFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FaqAPIClient.prototype.updateFaq = function updateFaq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FaqAPI.UpdateFaq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FaqAPIClient = FaqAPIClient;

