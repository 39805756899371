// package: 
// file: proto/notification_setting.proto

var proto_notification_setting_pb = require("../proto/notification_setting_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NotificationSettingsAPI = (function () {
  function NotificationSettingsAPI() {}
  NotificationSettingsAPI.serviceName = "NotificationSettingsAPI";
  return NotificationSettingsAPI;
}());

NotificationSettingsAPI.UpdateNewTicketNotificationUsers = {
  methodName: "UpdateNewTicketNotificationUsers",
  service: NotificationSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_notification_setting_pb.UpdateNewTicketNotificationUsersRequest,
  responseType: proto_notification_setting_pb.UpdateNewTicketNotificationUsersResponse
};

NotificationSettingsAPI.ListNewTicketNotificationUsers = {
  methodName: "ListNewTicketNotificationUsers",
  service: NotificationSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_notification_setting_pb.ListNewTicketNotificationUsersRequest,
  responseType: proto_notification_setting_pb.ListNewTicketNotificationUsersResponse
};

exports.NotificationSettingsAPI = NotificationSettingsAPI;

function NotificationSettingsAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NotificationSettingsAPIClient.prototype.updateNewTicketNotificationUsers = function updateNewTicketNotificationUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationSettingsAPI.UpdateNewTicketNotificationUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationSettingsAPIClient.prototype.listNewTicketNotificationUsers = function listNewTicketNotificationUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationSettingsAPI.ListNewTicketNotificationUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NotificationSettingsAPIClient = NotificationSettingsAPIClient;

